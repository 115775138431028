import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Reaptcha from "reaptcha"
import axios from "axios"
import styled from "styled-components"

import insta from "../images/icons/Icon_Instagram.svg"
import linkedin from "../images/icons/Icon_LinkedIn.svg"
import envelope from "../images/icons/Footer_envelop.svg"
import telephone from "../images/icons/Footer_phone.svg"
import dropPin from "../images/icons/Footer_location.svg"
import backIcon from "../images/icons/back-icon.png"
import qs from "qs";

const RequiredLabel = styled.p`
  font-family: Roboto Mono, monospace;
  font-size: 12px;
  letter-spacing: 3px;
  top: 5px;
  width: 100%;
  text-transform: uppercase;
  font-style: italic;
  line-height: 18px;
  text-align: left;
  color: #202020;
  padding: 0 1.5rem;
  margin-left: 8.33%;
  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
`

class Contact extends React.Component {
  state = {
    name: "",
    email: "",
    title: "",
    company: "",
    message: "",
    isTop: true,
    verified: true,
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  onVerify = recaptchaResponse => {
    this.setState({
      verified: true,
    })
  }

  expired = () => {
    this.setState({
      verified: false,
    })
  }
  // mailgun api call
  handleFormSubmit = () => {
    this.LOCAL_PATH = "http://localhost:8888/static/mailer/"
    this.PATH = "/mailer/";
    this.activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV;
    this.API_PATH = this.PATH;
    if(this.activeEnv === 'development'){
      this.API_PATH = this.LOCAL_PATH
    }
    const qs = require('qs');
    axios.post(this.API_PATH,
      qs.stringify(this.state, { parseArrays: false })
    )
    .then(result => {
      if(result.status === 200) {
        window.location.href= "/thankyou";
      }
    })
    .catch(error => console.log({ error: error.message }))
  }

  componentDidMount (){
    const height = document.querySelector('#contact-form').offsetHeight;
    document.querySelector('#contact-page').style.height = height + 150 + "px";
  }

  /******** Contact form **********/

  handleSubmit = event => {
    
    if (
      this.state.name.length > 1 &&
      this.state.email.length >= 5 &&
      /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.email) &&
      this.state.message.length > 1
    ) {
      event.preventDefault();
      this.handleFormSubmit();
    }
    else {
      event.preventDefault()
      if (this.state.name.length <= 1) {
        document.getElementsByClassName("NameInput")[0].style.borderColor =
          "red"
      } else if (this.state.name.length > 1) {
        document.getElementsByClassName("NameInput")[0].style.borderColor =
          "#202020"
      }

      if (/^[a-zA-Z0-9.-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.email)) {
        document.getElementsByClassName("EmailInput")[0].style.borderColor =
          "#202020"
      }
      if (!/^[a-zA-Z0-9.-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.email)) {
        document.getElementsByClassName("EmailInput")[0].style.borderColor =
          "red"
      }
      if (this.state.message.length <= 1) {
        document.getElementsByClassName("MessageInput")[0].style.borderColor =
          "red"
      }
      if (this.state.message.length > 1) {
        document.getElementsByClassName("MessageInput")[0].style.borderColor =
          "#202020"
      }
    }
  }

  render() {
    return (
      <Layout backgroundColor="#fff" noFooter>
        <SEO
          title="Contact Us"
          description="Contact The Bloc to join the team. Or, to learn more about how we can work together and help the world become a healthier place."
          url="www.thebloc.com/contact"
        />
        <h1 className="d-none">Contact The Bloc</h1>
        <div id="contact-page" className="contact-page">
          <div
            id="contact-form"
            className="container section"
            data-color="black"
          >
            <div className="row justify-content-md-center justify-content-lg-start">
              <div className="col-lg-7 offset-lg-1 contact-clients-header">
                <div className="back-btn">
                  <Link to="/" className="x-btn">
                    <img
                      src={backIcon}
                      alt="back to profile list"
                      loading="lazy"
                    />
                    <span className="back-title">back to Home</span>
                  </Link>
                </div>
              </div>
              <h1 className="col-lg-7 offset-lg-1">HOW CAN WE HELP?</h1>
              <RequiredLabel>*REQUIRED</RequiredLabel>

              <div className="col-lg-7 offset-lg-1">
                <form
                  onSubmit={this.handleSubmit}
                  // method="post"
                  // action="/mailer/index.php"
                  className="clients-fields"
                >
                  <input
                    className="NameInput required-field"
                    type="text"
                    name="name"
                    placeholder="NAME *"
                    id="name"
                    value={this.state.name}
                    onChange={this.handleInputChange}
                  />
                  <br />
                  <input
                    className="EmailInput required-field"
                    type="text"
                    name="email"
                    id="email"
                    placeholder="EMAIL *"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                  />
                  <br />
                  <input
                    className="TitleInput"
                    type="text"
                    name="title"
                    id="title"
                    placeholder="TITLE"
                    value={this.state.title}
                    onChange={this.handleInputChange}
                  />
                  <br />
                  <input
                    className="CompanyInput"
                    type="text"
                    name="company"
                    id="company"
                    placeholder="COMPANY"
                    value={this.state.company}
                    onChange={this.handleInputChange}
                  />
                  <br />
                  <textarea
                    className="MessageInput required-field"
                    rows="8"
                    id="message"
                    name="message"
                    placeholder="MESSAGE *"
                    value={this.state.message}
                    onChange={this.handleInputChange}
                  ></textarea>
                  <Reaptcha
                    sitekey="6Ldd1rQZAAAAAMrf-t9jACHMSbyAWUP5-T7XScvC"
                    onVerify={this.onVerify}
                    className="recatpcha"
                    onExpire={this.expired}
                  />
                  <button
                    style={{ fontWeight: "600", fontSize: "13px" }}
                    className="subBtn bloc-btn mobile-margin-auto"
                    type="submit"
                    text="submit"
                    disabled={!this.state.verified}
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
              <div></div>

              {/******** Contact Footer ************/}

              <div className="black-box mobile-box contact-black-box">
                <div className="contact-container">
                  <div className="contact-content">
                    <Container>
                      {/* <Row>
                        <Col className="mb-20">
                          <div className="contact-line"></div>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col
                          md={6}
                          lg={12}
                          className="contact-header-container"
                        >
                          <div className="contact-content contact-header">
                            THE BLOC
                          </div>
                          <div className="mobile-location">
                            <img
                              className="contact-content contact-icons"
                              src={dropPin}
                              alt=""
                              loading="lazy"
                            />
                            <a
                              className="contact-content contact-text"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.google.com/maps/search/The+Bloc,+32+Old+Slip,+15th+Floor,+New+York,+NY+10005/@40.7037586,-74.0099055,17z/data=!3m1!4b1"
                            >
                              32 Old Slip <br /> 15th Floor
                              <br />
                              New York, NY 10005
                            </a>
                          </div>
                          <div>
                            <img
                              className="contact-content contact-icons"
                              src={envelope}
                              alt=""
                              loading="lazy"
                            />
                            <a
                              className="contact-content contact-text"
                              href="mailto:sciencefoundry@thebloc.com"
                            >
                              sciencefoundry@thebloc.com
                            </a>
                          </div>
                          <div>
                            <img
                              className="contact-content contact-icons"
                              src={telephone}
                              alt=""
                              loading="lazy"
                            />
                            <div className="contact-content contact-text">
                              212-524-6200
                            </div>
                          </div>
                        </Col>
                        <Col md={6} lg={12} className="contact-socials">
                          <div className="contact-socials">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.instagram.com/_thebloc/"
                            >
                              <img
                                className="cotact-socials contact-social-grow"
                                src={insta}
                                alt=""
                              />
                            </a>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.linkedin.com/company/the-bloc/"
                            >
                              <img
                                className="cotact-socials contact-social-grow"
                                src={linkedin}
                                alt=""
                                loading="lazy"
                              />
                            </a>
                          </div>
                          <div className="contact-terms">
                            <a
                              className="contact-content contact-text"
                              href="/termsofuse"
                            >
                              Terms of use
                            </a>
                            &nbsp;
                            <span className="fiftypercent mobile-terms">|</span>
                            &nbsp;
                            <a
                              className="contact-content contact-text"
                              href="/privacypolicy"
                            >
                              Privacy policy
                            </a>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="footer-rights">
                          <i className="contact-content contact-rights">
                            &copy; {new Date().getFullYear()} The CementWorks, LLC. DBA The Bloc. All
                            Rights Reserved.
                          </i>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}


export default Contact;